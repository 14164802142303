import React from 'react';

// import footer data
 import {footerData} from '../data'

// import components
import Copyright from './Copyright'

const Footer = () => {
  // destructure footer data
  const { logo, address, email, phone, list1, list2, socialList } = footerData;
  return (
    <footer >
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row text-center xl:text-left gap-y-12'>
          {/* info */}
          <div className='w-[45%] mx-auto flex flex-col items-center xl:items-start'>
            {/* logo */}
            <a href='#'>
              <img className='mb-[0px]' src={logo} alt='' style={{ width: '100px', height: 'auto' }}/>
            </a>
            {/* address */}
            <div className='max-w-[260px] mb-5 text-primary font-bold'>
              {address}
            </div>
            {/* email */}
            <div className='font-light italic'>{email}</div>
            {/* phone */}
            <div className='font-light italic'>{phone}</div>
          </div>
          {/* lists */}
          <div className='flex flex-1 flex-col gap-y-14 xl:flex-row justify-between'>
            {/* list 1 */}

          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
}

export default Footer;