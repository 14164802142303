import React from 'react';

// import about data
import { aboutData } from '../data';

const About = () => {
  // Destructure about data
  const { image, title, subtitle } = aboutData;

  return (
    <section className='my-[30px] xl:mt-[100px]'>
      <div className='container mx-auto'>
        <div className='bg-accent-secondary/[15%] rounded-[50px] min-h-[560px] px-12 pb-12 flex flex-col xl:flex-row xl:items-center xl:text-left xl:gap-x-[60px] xl:pb-0'>
          {/* Image Container */}
          <div className='flex-1'>
            <img src={image} alt='' className='w-full h-auto rounded-[20px]' /> {/* Added classes for responsiveness */}
          </div>

          {/* Text Container */}
          <div className='flex-1 xl:pr-12'>
            <h2 className='h2 mb-10' data-aos='fade-up' data-aos-delay='300' style={{ color: 'rgb(153, 204, 0)' }}>
              {title}
            </h2>
            <p className='max-w-[474px] mx-auto xl:mx-0'>
              {subtitle}
            </p>
            <p className='max-w-[474px] mx-auto xl:mx-0' style={{ lineHeight: '2.2' }}>
              {"Bright field videos (e.g. 500x500 pixels, min 30 fps and 10s length) of the contracting rings can be analyzed using 4dcell's SmartExplorer software (SmartX 1.0)."}
            </p>
            <p className='max-w-[474px] mx-auto xl:mx-0' style={{ lineHeight: '2.2' }}>
             {"The software runs on the Google Cloud platform and uses machine learning to detect and track the area of the central pillar, from which it determines several parameters, including but not limited to:"}
            </p>
           <ul className='list-disc pl-10 ml-8 max-w-[474px] mx-auto xl:mx-0' style={{ lineHeight: '2.2' }}>
              <li>Contraction amplitude</li>
              <li>Contraction strain</li>
              <li>Contraction and relaxation speed</li>
              <li>Contraction stress</li>
            </ul>

          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
