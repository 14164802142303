import React, { useEffect } from 'react';
// import components
import Hero from '../components/Hero';
import About from '../components/About';
import Footer from '../components/Footer';
import DropFile from '../components/DropFile/DropFile';
import MaintenanceBanner from '../components/MaintenanceBanner';

const Home = () => {
    useEffect(() => {
        // Set the background color of the body element to slightly off-white
        document.body.style.backgroundColor = 'rgb(255, 250, 250)'; //'rgb(246, 252, 225)';
    }, []);

  const onFileChange = (files) => {
    console.log(files)
  }

  return (
    <div className={'overflow-hidden'} >
    <Hero/>
    <div className="box"
    style={{ backgroundColor: 'rgb(244, 247, 230)', boxShadow: 'none' }} >
      <h2 className='header'>
      </h2>
      <DropFile/>
    </div>
    <About/>
    <Footer/>
    </div>

  );
}

export default Home;