import React from "react";

// import hero data
import {heroData} from '../data.js'

const Hero=() => {

    // destructure hero data
    const {title, subtitle, image} = heroData

return (
    <section className={'lg:h-[500px] py-12 relative'}>
        <div className={"container mx-auto h-full"}>
            <div className={'flex flex-col xl:flex-row items-center h-full md:py-24'}>
                {/* text */}
                <div className='text-center xl:text-left absolute z-10'>
                    <h1 className='h1 xl:max-w-[500px] mb-4 xl:mb-10'
                        data-aos='fade-down'
                        data-aos-delay='500'
                        style={{ color: 'rgb(153, 204, 0)' , marginTop: '-265px' }}
                        > {title}
                        </h1>
                        <h2 className='h2 xl:max-w-[500px] mb-2 '
                        data-aos='fade-down'
                        data-aos-delay='500'
                        style={{ color: 'rgb(145, 191, 0)' }}
                        > {subtitle}
                        </h2>
                </div>
                {/* image */}
                    <div className='absolute z-0 xl:right-10 xl:bottom-0 xl:-translate-y-20 xl:-translate-x-20'>
                        <img
                            src={image}
                            className=' h-[400px]  opacity-80' // Modify size and position
                            alt="Hero"
                        />
                </div>
            </div>
        </div>
    </section>
);

    };

export default Hero;