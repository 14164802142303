import React from 'react';

const PageNotFound = () => {

    return(
        <div className={'overflow-hidden'}>
            <h1> 404 Page not found... :( </h1>
        </div>
    );
}

export default PageNotFound;