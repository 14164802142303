import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

const MaintenanceBanner = () => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#fff3cd',
      border: '1px solid #ffeeba',
      borderRadius: '5px',
      color: '#856404'
    }}>
      <WarningIcon style={{ color: '#856404', marginRight: '7px'}} />
      <span>
        Website Under Maintenance
      </span>
    </div>
  );
};

export default MaintenanceBanner;
