import React, {useState} from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

// import animate on scroll
import Aos from 'aos'
import 'aos/dist/aos.css'

import "./App.css";
import Hero from "./components/Hero";
import About from "./components/About";
import Footer from "./components/Footer";

// import pages
import Home from "./pages/Home"
import PageNotFound from "./pages/PageNotFound"


function App() {

// aos initialization
Aos.init(
    {
        duration: 1800,
        offset: 0,
    }
)

  return (
      <Router>
          <Routes>
              <Route path={'/'} element={<Home />}/>
              <Route path={"*"} element={<PageNotFound/>} />
          </Routes>
      </Router>
  );
}

export default App;